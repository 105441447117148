<template>
<div class="waiting-view">
    <div class="heading" v-if="!afterQuiz">
        <h1>{{ $t('Waiting.beforeQuizTitle') }}</h1>
        <p>
            {{ $t('Waiting.beforeQuizMessage') }}
        </p>
    </div>

    <div class="heading" v-else>
        <h1>{{ $t('Waiting.afterQuizTitle') }}</h1>
        <p>
            {{ $t('Waiting.afterQuizMessage') }}
        </p>
    </div>
    <div class="action-selection__content">

        <div class="outer-circle">
            <div class="inner-circle">
                <vs-avatar circle badge badge-color="primary" size="100">
                    <img :src="me.avatar" />
                    <template #badge>
                        <img :src="me.tag" style="width: 32px; height: 32px;" />
                    </template>
                </vs-avatar>
            </div>
        </div>

    </div>

    <div class="avatars-animation">
        <vs-avatar circle badge badge-color="primary" size="100" v-for="avatar in avatars" :key="avatar.index" style="position:absolute;" :style="{top: avatar.y + 'px', left: avatar.x + 'px'}" :class="{'full-size-emoji': afterQuiz}">
            <img :src="avatar.src" />
            <template #badge>
                <img :src="avatar.badge" style="width: 32px; height: 32px;" />
            </template>
        </vs-avatar>
    </div>
</div>
</template>

<script>
import {partecipantService, fileService, eventService} from '@/rest';
// import Avatar 1 to 8



import ArdenteIcon from '../assets/images/tags/ardente.png';
import DevilIcon from '../assets/images/tags/devil.png';
import DiscretoIcon from '../assets/images/tags/discreto.png';
import InnamoratoIcon from '../assets/images/tags/innamorato.png';
import InnocenteIcon from '../assets/images/tags/innocente.png';
import MaliziosoIcon from '../assets/images/tags/malizioso.png';
import QueenIcon from '../assets/images/tags/queen.png';
import RomanticoIcon from '../assets/images/tags/romantico.png';
import SuperdotatoIcon from '../assets/images/tags/superdotato.png';
import TimidoIcon from '../assets/images/tags/timido.png';
import participantService from '@/rest/services/partecipant';

export default {
    name: 'Waiting',

    props: {
        afterQuiz: {
            type: Boolean,
            default: false
        },
    },

    components: {},
    data: () => ({
        tags: {
            'ARDENTE': ArdenteIcon,
            'DEVIL': DevilIcon,
            'DISCRETO': DiscretoIcon,
            'INNAMORATO': InnamoratoIcon,
            'INNOCENTE': InnocenteIcon,
            'MALIZIOSO': MaliziosoIcon,
            'QUEEN': QueenIcon,
            'ROMANTICO': RomanticoIcon,
            'SUPERDOTATO': SuperdotatoIcon,
            'TIMIDO': TimidoIcon
        },

        avatars: [],

        interval: null,
        index: 0,

        knownPartecipants: [],

        event: null,

        me: {
            avatar: null,
            tag: null
        }
    }),

    methods: {
        animationTick() {
            // the avatars must goes to the top with a randomic sin wave
            this.avatars = this.avatars.map(avatar => {
                avatar.y -= avatar.speed; // Use random speed for vertical movement
                avatar.x += Math.sin(avatar.y * avatar.frequency) * avatar.amplitude;
                return avatar;
            });

            // remove the avatars that are out of the screen
            this.avatars = this.avatars.filter(avatar => avatar.y > -100);
        },

        async addAvatar(participant) {
            // device w and h
            const w = window.innerWidth;
            const h = window.innerHeight;

            let src = null;
            try {
                const fileResult = await fileService.getSignedDownloadURL({
                    name: participant.id,
                });
                src = fileResult.url;
            } catch (e) {
                console.error(e);
            }

            // random badge
            const badge = this.tags[participant.tag] || this.tags['ROMANTICO'];

            this.avatars.push({
                index: this.index,
                // x random number betwen 0 and w
                x: Math.floor(Math.random() * w),
                y: h + 100, // start at the bottom
                // random between 1 and 5
                amplitude: Math.random() * (5 - 1) + 1,
                // Random frequency between 0.05 and 0.01
                frequency: Math.random() * (0.05 - 0.01) + 0.01,
                speed: Math.random() * (2 - 1) + 1,
                src,
                badge,
            });

            this.index++;
        },

        refreshPartecipantsList(){
            partecipantService.get().then(result => {
                const partecipants = result.data;
                if(partecipants){
                    let newPartecipants = partecipants.filter(partecipant => !this.knownPartecipants.includes(partecipant.id));
                    newPartecipants = newPartecipants.filter(partecipant => partecipant.picture_updated_at != null); // show only with picture
                    this.knownPartecipants = this.knownPartecipants.concat(newPartecipants.map(partecipant => partecipant.id));

                    for (let i = 0; i < newPartecipants.length; i++) {
                        // wait rand interval between 1000 and 1500ms
                        setTimeout(() => {
                            this.addAvatar(newPartecipants[i]);
                        }, Math.random() * (1500 - 1000) + 1000);
                    }
                }

                if(this.$route.name == 'Waiting'){
                    // call refreshPartecipantsList after 3 seconds
                    setTimeout(this.refreshPartecipantsList, 3000);
                }
            });

            eventService.detail({
                id: this.event.id
            }).then(event => {
                if(!this.afterQuiz && event.status == 'PREMATCH_QUIZ'){ // i was coming from On Boarding and event became PREMATCH_QUIZ
                    this.$router.push({
                        name: 'Quiz',
                        params: {
                            event: this.event
                        }
                    });
                } else if(event.status == 'IN_PROGRESS'){ // anyway if event became IN_PROGRESS
                    this.$router.push({
                        name: 'Matches'
                    });
                }
            }).catch(e => {
                console.error(e);
            });
        }
    },

    mounted() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }

        this.interval = setInterval(this.animationTick, 1000 / 25); // 25fps

        

        participantService.detail().then(p => {
            this.event = p.event;
            fileService.getSignedDownloadURL({
                name: p.id
            }).then(result => {
                this.me.avatar = result.url;
            }).catch(e => {
                console.error(e);
            });
            this.me.tag = this.tags[p.tag] || this.tags['ROMANTICO'];

            this.refreshPartecipantsList();
        }).catch(e => {
            console.error(e);
            this.$router.push({
                name: 'OnBoarding'
            });
        });
    }
}
</script>

<style>
.waiting-view .vs-avatar__badge {
    background: transparent;
    border: unset !important;
    left: -64px;
    bottom: -10px;
}

.waiting-view .vs-avatar__badge img {
    /* png shadow filter */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
}

.waiting-view .full-size-emoji .vs-avatar__badge {
    left: 0px;
    bottom: 18px;
}

.waiting-view .full-size-emoji .vs-avatar__badge img {
    width: 64px!important;
    height: 64px!important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.50));
}
</style><style scoped>
.avatars-animation {
    overflow: hidden !important;
    max-width: 100vw;
    width: 100vw;
    overflow-x: hidden !important;

}

.waiting-view .heading {
    position: absolute;
    top: 3rem;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.outer-circle,
.outer-circle>div {
    padding: 2rem;
    background: rgba(36, 181, 201, 0.3);
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 100%;
}

.inner-circle {
    background: rgba(36, 181, 201, 0.6) !important;
}

.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    gap: 1rem;
    padding-top: 3rem;
    background: linear-gradient(180deg, rgba(233, 254, 255, 0.85) 16.57%, rgba(37, 142, 156, 0.00) 53.63%);
    backdrop-filter: blur(1px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #000;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}

.waiting-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    background: white;
    overflow-x: hidden;
}
</style>
